.turbo-progress-bar {
  background-color: $navy;
  height: 3px;
}

.alert {
  p {
    margin: 0;
  }
}
