.fa-success {
  color: #5cb85c;
}

.fa-important {
  color: #a94442;
}

.phone-flag {
  padding-left: 3px;
}
