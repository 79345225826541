// Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

// FontAwesome
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

// Load Inspinia Theme and plugins
@import "../../vendor/stylesheets/inspinia/base.scss";
@import "../../vendor/stylesheets/inspinia/custom/icons.scss";

.concerto-logo {
  content:url('../../vendor/images/concerto/logos/back-office.png')
}
