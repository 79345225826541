.table tbody > tr > td.vert-align {
  vertical-align: middle;
}

.table > tbody > tr.danger.selected  > td,
.table > tbody > tr.success.selected > td,
.table > tbody > tr.warning.selected > td,
.table > tbody > tr.special.selected > td {
  background-color: #0085c7;
  color: #fff;
  a {
    color: #fff;
  }
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: $navy;
  color: #fff;
  a {
    color: #fff;
  }
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: $yellow;
  color: #fff;
  a {
    color: #fff;
  }
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: $red;
  color: #fff;
  a {
    color: #fff;
  }
}

.table > thead > tr > td.special,
.table > thead > tr > th.special,
.table > thead > tr.special > td,
.table > thead > tr.special > th,
.table > tbody > tr > td.special,
.table > tbody > tr > th.special,
.table > tbody > tr.special > td,
.table > tbody > tr.special > th,
.table > tfoot > tr > td.special,
.table > tfoot > tr > th.special,
.table > tfoot > tr.special > td,
.table > tfoot > tr.special > th {
  background-color: $purple;
  color: #fff;
  a {
    color: #fff;
  }
}
